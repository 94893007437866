import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  errorMessage: {
    color: `${palette.error.main} !important`,
    margin: 0,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
    display: 'flex !important',
  },
  formControl: {
    width: '100%',
    gap: spacing(8),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputLabel: {
    color: palette.gray[64],
    fontSize: spacing(12),
  },
  inputLabelTextArea: {
    color: palette.gray[64],
    fontSize: spacing(12),
    marginBottom: spacing(4),
  },
  textArea: {
    marginTop: spacing(8),
    '& .MuiInput-underline:before': {
      display: 'none',
    },

    '& .MuiInput-underline.Mui-error:after': {
      display: 'none',
    },

    '& .MuiInput-underline:after': {
      display: 'none',
    },

    '& .MuiInputBase-input': {
      padding: spacing(10),
    },
  },
  inputValue: {
    fontSize: spacing(16),
  },
  labelDisabled: {
    color: `${Theme.Colors.Grayscale.ThirtyTwo} !important`,
  },
  cursorDisabled: {
    cursor: 'not-allowed !important',
  },
  required: {
    color: Theme.Colors.Red.Base,
    marginLeft: spacing(4),
  },
  rulesField: {
    padding: 0,
    gap: spacing(8),
  },
  noGutters: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 4,
    paddingTop: 4,
    margin: 0,
    fontSize: spacing(12),
    height: 'auto',
  },
}))
