/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  InputLabel,
  FormControl,
  List,
  ListItem,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { TextField } from '@mui/material'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import generalInputStyles from '../styles'

/**
 * Basic input component that uses Material-UI TextField and React Hook Form Controller.
 * @param {Object} props - The props object.
 * @param {string} props.label - The label of the input.
 * @param {string} props.name - The name of the input. (Hook-Form related)
 * @param {boolean} [props.required=false] - Whether the input is required or not.
 * @param {string} [props.defaultValue=''] - The default value of the input.
 * @param {Object} [props.rules={}] - The validation rules for the input. Follows the same rules as React Hook Form.
 * @param {Object} [props.style={}] - The style object to be applied to the component.
 * @param {string} [props.containerClasses] - The classes to be applied to the container element.
 * @param {string} [props.labelClasses] - The classes to be applied to the label element.
 * @returns {JSX.Element} - The BasicInput component.
 */

export const PasswordRules = ({ error = false }) => {
  const generalStyles = generalInputStyles()
  const rules = [
    'Mínimo de 8 caracteres',
    'Pelo menos uma letra minúscula',
    'Pelo menos um caracter especial (ex: !, @, #, $)',
    'Pelo menos um número',
  ]
  return (
    <List className={generalStyles.rulesField}>
      {rules.map(rule => (
        <ListItem
          key={rule}
          className={[generalStyles.noGutters, error && generalStyles.errorMessage]}
        >
          {rule}
        </ListItem>
      ))}
    </List>
  )
}

function PasswordInput({
  label,
  name,
  required = false,
  defaultValue = '',
  rules = {},
  style = {},
  containerClasses,
  labelClasses = null,
  customErrorMessage = '',
  textArea = false,
  disabled = false,
  ...rest
}) {
  const generalStyles = generalInputStyles()

  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = useCallback(() => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }, [])

  const inputProps = useMemo(
    () => ({
      endAdornment: !disabled && (
        <InputAdornment position="end">
          <IconButton aria-label="Mostrar a senha" edge="end" onClick={toggleShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }),
    [showPassword, toggleShowPassword, disabled]
  )

  const { errors, control, setValue } = useFormContext()

  const errorMessage = useMemo(
    () => errors?.[`${name}`]?.message || customErrorMessage,
    [errors?.[name], customErrorMessage]
  )

  useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue, name, setValue])

  return (
    <FormControl
      style={{ ...style }}
      className={classNames(generalStyles.formControl, containerClasses ?? null)}
    >
      <InputLabel
        variant="standard"
        id={`${name}-label`}
        style={{ color: errorMessage ? '#C90000' : null, top: '-39px' }}
        className={classNames(
          textArea ? generalStyles.inputLabelTextArea : generalStyles.inputLabel,
          labelClasses ?? null
        )}
      >
        {label}
        {required && <span className={generalStyles.required}>*</span>}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        as={
          <TextField
            id={name}
            variant="standard"
            defaultValue={defaultValue}
            error={!!errorMessage}
            required={required}
            type={showPassword && !disabled ? 'text' : 'password'}
            InputProps={{
              style: {
                fontSize: 16,
                borderRadius: '8px',
                border: `${textArea ? '1px' : 0} solid ${errorMessage ? '#C90000' : '#717171'}`,
              },
              ...inputProps,
            }}
            disabled={disabled}
            className={[textArea && generalStyles.textArea]}
            {...rest}
          />
        }
      />
      <PasswordRules error={!!errorMessage} />
    </FormControl>
  )
}

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  defaultValue: PropTypes.string,
  rules: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  containerClasses: PropTypes.string,
  labelClasses: PropTypes.string,
}

PasswordInput.defaultProps = {
  required: false,
  showErrorMessage: true,
  defaultValue: '',
  rules: {},
  style: {},
  containerClasses: null,
  labelClasses: null,
}

export default PasswordInput
