import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { InputLabel } from '@material-ui/core'
import { FormHelperText, TextField } from '@mui/material'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import generalInputStyles from '../styles'

/**
 * MultilineInput component for rendering a multiline input field with label and error message.
 * @param {Object} props - The props object for the MultilineInput component.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field. (Hook-Form related)
 * @param {boolean} [props.required=false] - Whether the input field is required or not.
 * @param {string} [props.defaultValue=''] - The default value for the input field.
 * @param {boolean} [props.disabled=false] - Whether the input field is disabled or not.
 * @param {Object} [props.style={}] - The style object for the container div.
 * @param {string} [props.containerClasses=null] - The class names for the container div.
 * @param {string} [props.labelClasses=null] - The class names for the label.
 * @param {string} [props.inputClasses=null] - The class names for the input field.
 * @returns {React.ReactElement} - A React component that displays a multiline input field with label and error message.
 */
function MultilineInput({
  label,
  name,
  required = false,
  defaultValue = '',
  disabled = false,
  style = {},
  minRows = 12,
  maxRows = 12,
  containerClasses,
  labelClasses,
  inputClasses,
  ...rest
}) {
  const generalStyles = generalInputStyles()

  const { control, errors, setValue } = useFormContext()

  useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue, name, setValue])

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', ...style }}
      className={classNames(
        generalStyles.formControl,
        disabled ? generalStyles.cursorDisabled : null,
        containerClasses ?? null
      )}
    >
      <InputLabel
        variant="standard"
        id={`${name}-label`}
        style={{ color: errors[name] ? '#C90000' : null }}
        className={classNames(
          generalStyles.inputLabel,
          labelClasses ?? null,
          disabled ? generalStyles.labelDisabled : null
        )}
      >
        {label}
        {required && <span className={generalStyles.required}>*</span>}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required: { value: required, message: 'Esse campo é obrigatório' } }}
        as={
          <TextField
            id={name}
            variant="outlined"
            defaultValue={defaultValue}
            className={classNames(inputClasses ?? null)}
            error={!!errors[name]}
            multiline
            minRows={minRows}
            maxRows={maxRows}
            disabled={disabled}
            InputProps={{ style: { fontSize: 16, padding: '8px 10px' }, disabled }}
            {...rest}
          />
        }
      />
      <FormHelperText className={generalStyles.errorMessage} error={!!errors[name]}>
        {errors[name]?.message}
      </FormHelperText>
    </div>
  )
}

MultilineInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  defaultValue: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  containerClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  disabled: PropTypes.bool,
}

MultilineInput.defaultProps = {
  required: false,
  defaultValue: '',
  style: {},
  minRows: 12,
  maxRows: 12,
  containerClasses: null,
  labelClasses: null,
  inputClasses: null,
  disabled: false,
}

export default MultilineInput
